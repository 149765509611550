import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * Pipedrive Leadbooster Webform - To collect the analytics via pipedrive
 * @param {String} url link to the form
 */
const WebForm = ({ url }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://webforms.pipedrive.com/f/loader';
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return <div className="pipedriveWebForms" data-pd-webforms={url} />;
};

WebForm.propTypes = {
  url: PropTypes.string,
};

export default WebForm;
